import React from 'react';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap'
import {
    useParams,
} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { IoLogoWhatsapp, IoChevronBack, IoCart } from "react-icons/io5";

import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import utils from '@Components/utils';
const Header = () => {
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars


    console.warn("cookie phone => ", cookies.alani360info?.phone)

    const dataReducer = useSelector(state => state.dataReducer);
    const {
        cart_items,
        account
    } = dataReducer;
    React.useEffect(() => {
        if (!account) return;
        if (!account.country) return;
        setCookie('alani360', account.country, { path: '/' });
        setCookie('alani360info', { phone: account.phone }, { path: '/' });
    }, [account, setCookie]);
    const token = useParams().token;
    return <>
        <Navbar bg="light" expand="lg"
            style={{
                position: 'sticky',
                top: 0,
                zIndex: 102,
            }}
        >
            <Container>

                <a
                    style={{ fontSize: '1.5rem', marginRight: '1rem' }}
                    target="_blank" rel="noreferrer" href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}`}
                >
                    <IoChevronBack />
                    <IoLogoWhatsapp />
                </a>
                <Navbar.Brand
                    style={{
                        fontSize: '1.2rem',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                        }}
                    >
                        <div

                            className='d-flex flex-row justify-content-center align-items-center me-4'
                        >
                            {!!account &&
                                <span>
                                    &nbsp;
                                    <img
                                        style={{
                                            width: 30,

                                        }}
                                        alt=""
                                        src={`/images/flags/${cookies.alani360}.png`}
                                    />
                                </span>
                            }

                        </div>
                        <img
                            style={{
                                height: 55,
                            }}
                            src="/images/bby_logo.png"
                            alt="Powered by Alani360"
                        />
                        <div
                            style={{
                                display: 'flex',
                                marginLeft: 10,
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <div style={{
                                fontSize: '0.45rem',
                                color: '#808080',
                                marginLeft: 12,
                                marginBottom: 5,
                            }}>powered by</div>
                            <img
                                style={{
                                    width: 65,
                                    marginLeft: 10,
                                    marginRight: 10,
                                    bottom: 15,
                                    position: 'absolute',
                                }}
                                src="/images/alani360_1.png"
                                alt="Powered by Alani360"
                            />
                        </div>

                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to={`/`} >
                            <Nav.Link  >Inicio</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${token}/marcas`} >
                            <Nav.Link  >Marcas</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${token}/consideraciones`} >
                            <Nav.Link  >Consideraciones</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${token}/terms`} >
                            <Nav.Link  >T&C</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${token}/orders`} >
                            <Nav.Link  >Ordenes</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${token}/account`} >
                            <Nav.Link  >Mi&nbsp;Cuenta</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${token}/buscar`} >
                            <Nav.Link  >Buscar</Nav.Link>
                        </LinkContainer>
                        {cart_items?.length > 0 &&
                            <LinkContainer to={`/${token}/cart`} >
                                <Nav.Link >Carrito <IoCart color='blue' /></Nav.Link>
                            </LinkContainer>
                        }

                        <Nav.Link
                            target="_blank" rel="noreferrer" href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}?text=Ayuda`} className="div-row"
                        ><IoLogoWhatsapp /> Contacto {utils.getWhatsappNumber(cookies.alani360)}</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    </>


}

export default Header;