const utils = {
    ProperCase: (str) => {
        return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
    },

    urlencode: (str) => {
        return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
            return '%' + c.charCodeAt(0).toString(16);
        });
    },
    formatCurrency: (amount, currency) => {
        //currency viene del backend arn:aws:lambda:us-east-1:645160913840:function:BBY_product_fetch_by_sku
        if (!amount) return "N/A";

        let val = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: currency || 'MXN',
            minimumFractionDigits: 2
        }).format(amount);
        //if (currency === "MXN") return val.replace("$", "Pesos ")
        return val;
    },
    getWhatsappNumber: (countryCode) => {
        switch (countryCode) {
            case 'CR':
                return '+13259999181';
            case 'MX':
                return '+19546562300';
            case 'CO':
                return '+13054499666';
            default:
                return '+19547589387';
        }
    },
    countries: ["MX", "CR", "PE", "CL", "CO", "DO"],
    countryName: (countryCode) => {
        switch (countryCode) {
            case "DO":
                return 'Rep Dominicana';
            case 'MX':
                return 'México';
            case 'CR':
                return 'Costa Rica';
            case 'PE':
                return 'Perú';
            case 'CL':
                return 'Chile';
            case 'CO':
                return 'Colombia';
            default:

                return '[utils]';
        }
    }
}
export default utils;