import { useState, } from "react";

import {
    useNavigate,
} from "react-router-dom";

import {
    Button,
    Spinner,
    ToastContainer,
    Toast,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';

import api from '@Services/api';

import utils from '@Components/utils';
export default function EbanxDirectPay({
    setPaymentMethod,
    paymentMethod,
    token
}) {

    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        account,
        cart,
    } = dataReducer; // eslint-disable-line no-unused-vars
    return (
        <>

            <ToastContainer
                position='middle'

                bg='danger'
            >
                <Toast show={showToast}
                    style={{
                        maxWidth: 300,
                    }}
                    bg='success'
                    onClose={() => {
                        setShowToast(false);
                    }}
                >
                    <Toast.Body
                        className='text-white text-center'
                    >


                        {
                            (paymentMethod === 'oxxo' &&
                                cart?.all_in_price_total > 10000) ?
                                <div  >
                                    El monto máximo para pagar con OXXO
                                    <br />es de $10 000

                                    <div>
                                        <Button
                                            onClick={() => {
                                                setPaymentMethod('');
                                                setShowToast(false);
                                            }}
                                            variant="danger"
                                            className="mt-2"

                                        >
                                            Cancelar
                                        </Button>
                                    </div>


                                </div>
                                :
                                <>
                                    Quieres continuar con el pago
                                    <br />
                                    usando {`${paymentMethod.toUpperCase()}`}?


                                    <div className="m-2 mb-2">
                                        <img src={`/images/pago/${paymentMethod}.png`}
                                            alt={paymentMethod}
                                            style={{ height: 30 }} />

                                    </div>

                                    <h5>Total {utils.formatCurrency(cart?.all_in_price_total, cart?.currency)}</h5>

                                    <div
                                        style={{
                                            backgroundColor: '#fff',
                                            borderRadius: 5,
                                        }}
                                        className="d-flex justify-content-center align-items-center p-2"
                                    >
                                        <Button
                                            disabled={fetching}
                                            onClick={() => {
                                                setPaymentMethod('');
                                                setShowToast(false);
                                            }}
                                            variant="outline-danger"
                                            className="me-5"
                                        >
                                            Cancelar
                                        </Button>

                                        <Button
                                            disabled={fetching}
                                            variant="success"
                                            onClick={() => {

                                                const data = {
                                                    method: 'POST',
                                                    payload: {
                                                        account_id: account?.account_id,
                                                        cart_id: cart?.shopping_cart_id,
                                                        payment_type_code: paymentMethod,
                                                        token
                                                    }
                                                }

                                                setFetching(true);
                                                api.cart.mex_payment_ebanx(data).then(res => {
                                                    console.warn("api pay response => ", res);


                                                    if (res.error) {
                                                        alert(res.status_message);

                                                    } else {
                                                        navigate(`/${token}/orders/${cart?.shopping_cart_id}?status=success&paymentMethod=${paymentMethod}`);

                                                    }

                                                    setFetching(false);

                                                });
                                            }}
                                        >
                                            {
                                                fetching ?
                                                    <div className="m-2 d-flex justify-content-center align-items-center">
                                                        <Spinner
                                                            animation="border"
                                                            role="status"
                                                            style={{ width: 10, height: 10, borderWidth: 2 }}
                                                        />
                                                    </div>
                                                    :
                                                    "Continuar"
                                            }
                                        </Button>
                                    </div>
                                </>
                        }






                    </Toast.Body>
                </Toast>
            </ToastContainer>
            {(account?.country === 'CO') && <>

                <Button
                    style={{ width: 140 }}
                    className="d-flex align-items-center flex-row mb-1"
                    onClick={() => {

                        setShowToast(true);
                        setPaymentMethod(paymentMethod === 'nequi' ? '' : 'nequi')
                    }}
                    variant={paymentMethod === 'nequi' ? 'primary' : 'outline-primary'}

                >
                    <img src="/images/pago/nequi.png" alt="NEQUI" style={{ height: 20, marginRight: 10 }} /> NEQUI
                </Button>
            </>
            }

            {(account?.country === 'MX') && <>
                <Button
                    style={{ width: 140 }}
                    className="d-flex align-items-center flex-column mb-1"
                    onClick={() => {
                        setShowToast(true);

                        setPaymentMethod(paymentMethod === 'oxxo' ? '' : 'oxxo')
                    }}
                    variant={paymentMethod === 'oxxo' ? 'primary' : 'outline-primary'}

                >
                    <div>
                        <img src="/images/pago/oxxo.png" alt="OXXO" style={{ height: 20, marginRight: 10 }} /> OXXO
                    </div>
                </Button>

                <Button
                    style={{ width: 140 }}
                    variant={paymentMethod === 'spei' ? 'primary' : 'outline-primary'}
                    className="d-flex align-items-center align-content-center flex-column mb-1"
                    onClick={() => {
                        setShowToast(true);
                        setPaymentMethod(paymentMethod === 'spei' ? '' : 'spei')
                    }}

                >
                    <div>
                        <img src="/images/pago/spei.png" alt="SPEI" style={{ height: 10, marginRight: 10 }} />
                        SPEI
                    </div>
                </Button>


                <Button
                    style={{ width: 140 }}
                    variant={paymentMethod === 'spei' ? 'primary' : 'outline-primary'}
                    className="d-flex align-items-center align-content-center flex-column mb-1"
                    onClick={() => {
                        setShowToast(true);
                        setPaymentMethod(paymentMethod === 'mercadopago' ? '' : 'mercadopago')
                    }}

                >
                    <img src="/images/pago/mercadopago.png" alt="SPEI" style={{ height: 25 }} />
                </Button>

            </>

            }


        </>
    );
}